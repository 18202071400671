<template>
  <div class="wrap">
    <!-- 顶部黑块 -->
    <div class="wrapTop">
      <!-- 缩宽65% -->
      <div class="wrapTops">
        <!-- 上方课程名 -->
        <div class="wrapTopsTop">
          <!-- 左侧标题 -->
          <div class="wrapTopsTopL">
            <img src="@/assets/img/kecheng/videoIcon.png" />
            <p>
              {{ detail.title }}
            </p>
          </div>
          <!-- 右侧返回课程介绍 -->
          <div class="wrapTopsTopR" @click="$router.back()">
            <img src="@/assets/img/kecheng/back.png" />
            <span>返回课程介绍</span>
          </div>
        </div>
        <!-- 视频内容 -->

        <video-room
          v-if="detail.isLive == 3"
          :courseId="id"
          :showId="id"
          :type="3"
          :ccConfig="detail ? detail.aliSrc : ''"
        />
        <live-room
          :type="3"
          v-if="detail.isLive == 1"
          :userid="theRequest.userid"
          :roomid="theRequest.roomid"
        />
      </div>
    </div>
    <!-- 选项栏 -->
    <div class="warpnav">
      <div class="warpnavs">
        <!-- 课程概述 选中 -->
        <div class="warpnavsCon">
          <span>课程概述</span>
          <div></div>
        </div>
      </div>
    </div>
    <!-- 课程概述内容 -->
    <div class="warpCon">
      <div class="apple">
        <div class="appleLeft">
          <div class="appleLefts" v-html="detail.content"></div>
        </div>
        <!-- 右侧推荐 -->
        <you-love :id="id" :type="4" />
      </div>
    </div>
  </div>
</template>
<script>
import youLove from "@/components/Know/youLove.vue";
import { getDetail } from "@/api/home";
import LiveRoom from "@/components/Know/liveRoom.vue";
import videoRoom from "@/components/Know/videoRoom.vue";
export default {
  components: { youLove, LiveRoom, videoRoom },
  data() {
    return {
      detail: {}, //公开课详情
      tipsShow: true,
      // 章节详情信息
      theRequest: {},
      Id: null,
    };
  },
  async created() {
    this.id = this.$route.query.id;
    await getDetail(this.$route.query.id).then((res) => {
      this.detail = res.data.GKK;
      if (this.detail.ccConfig) {
        let indet = this.detail.ccConfig.indexOf("?");
        if (indet != -1) {
          var str = this.detail.ccConfig.substr(indet + 1);
          var strs = str.split("&");
          for (var i = 0; i < strs.length; i++) {
            this.theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
          }
        }
      }
    });
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.wrap {
  width: 100%;
  height: 100%;
  // 顶部黑块
  .wrapTop {
    width: 100%;
    height: 662px;
    background: #282726;
    // 缩宽65%
    .wrapTops {
      width: 65%;
      height: 100%;
      margin: auto;
      overflow: hidden;
      // 上方课程名
      .wrapTopsTop {
        width: 100%;
        height: 18px;
        margin: 19px 0px 19px 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        // 左侧标题
        .wrapTopsTopL {
          width: auto;
          height: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          cursor: pointer;
          // 图片
          img {
            width: 21px;
            height: 100%;
            margin-right: 11px;
          }
          // 文字标题
          p {
            width: auto;
            height: auto;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #aaaaaa;
            span {
              color: #ffffff;
            }
          }
        }
        // 右侧返回课程介绍
        .wrapTopsTopR {
          width: auto;
          height: auto;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          cursor: pointer;
          // 文字
          span {
            width: auto;
            height: auto;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
          }
          // 返回图片
          img {
            width: 13px;
            height: 11px;
            margin-right: 5px;
          }
        }
      }
      // 视频内容
      .wrapTopsCen {
        width: 100%;
        height: 577px;
        // 视频上方提示
        .wrapTopsCenTop {
          width: 100%;
          height: 40px;
          background: #ffffff;
          display: flex;
          justify-content: space-between;
          align-items: center;
          // 左侧提示
          .wrapTopsCenTopL {
            width: auto;
            height: 16px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            img {
              width: 16px;
              height: 16px;
              margin: 0px 10px 0px 10px;
            }
            span {
              width: auto;
              height: auto;
              font-size: 12px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #f15817;
            }
          }
          // 右侧关闭
          .wrapTopsCenTopR {
            width: auto;
            height: 12px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            cursor: pointer;
            span {
              width: auto;
              height: auto;
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #a8abbe;
            }
            img {
              width: 11px;
              height: 11px;
              margin: 0px 10px 0px 5px;
            }
          }
        }
        // 中间视频
        .wrapTopsCenCen {
          width: 100%;
          height: 536px;
          margin-top: 1px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          // 左视频
          .wrapTopsCenCenL {
            width: 75%;
            height: 100%;
            background: #f2f2f2;
            .prism-player {
              width: 100%;
              height: 100%;
            }
            video {
              width: 100%;
              height: 100%;
            }
          }
          // 右头像/聊天
          .wrapTopsCenCenR {
            width: 25%;
            height: 100%;
            // 讲师头像
            .wrapTopsCenCenRT {
              width: 100%;
              height: 190px;
              background: #ffffff;
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: center;
              img {
                width: 135px;
                height: 113px;
                margin-top: 26px;
              }
              span {
                width: auto;
                height: auto;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #6a6a6a;
              }
            }
            // 聊天框
            .wrapTopsCenCenRC {
              width: 100%;
              height: 346px;
              // 聊天框顶部
              .wrapTopsCenCenRCa {
                width: 100%;
                height: 40px;
                background: #464646;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #efefef;
              }
              // 聊天框内容
              .wrapTopsCenCenRCb {
                width: 100%;
                height: 260px;
                overflow: auto;
                // overflow-y:scroll;
                // overflow-x:hidden
                // 内容盒子
                .wrapTopsCenCenRCbs {
                  width: 85%;
                  height: 32px;
                  margin: 20px auto;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  // 左侧头像
                  .wrapTopsCenCenRCbsL {
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                    margin-right: 10px;
                    img {
                      width: 100%;
                      height: 100%;
                      border-radius: 50%;
                    }
                  }
                  // 右侧昵称/留言
                  .wrapTopsCenCenRCbsR {
                    width: auto;
                    height: 32px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: flex-start;
                    span {
                      width: auto;
                      height: auto;
                      font-size: 12px;
                      font-family: Microsoft YaHei;
                      font-weight: 400;
                      color: #b0b0b0;
                    }
                    p {
                      width: auto;
                      height: auto;
                      font-size: 12px;
                      font-family: Microsoft YaHei;
                      font-weight: 400;
                      color: #ffffff;
                    }
                  }
                }
              }
              // 聊天框底部
              .wrapTopsCenCenRCc {
                width: 100%;
                height: 46px;
                background: #5d5d5d;
                display: flex;
                justify-content: center;
                align-items: center;
                // 内容盒子
                .wrapTopsCenCenRCcs {
                  width: 85%;
                  height: 30px;
                  background: #7a7a7a;
                  border-radius: 15px;
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;
                  // 输入框
                  input {
                    width: 70%;
                    height: 100%;
                    border: none;
                    outline: none;
                    background: #7a7a7a;
                    color: #ffffff;
                  }
                  // 修改输入框预输入文字
                  input::-webkit-input-placeholder {
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #efefef;
                  }
                  // 发送
                  div {
                    width: 24%;
                    height: 100%;
                    background: #a1a1a1;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 0px 15px 15px 0px;
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #efefef;
                    cursor: pointer;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  // 选项栏
  .warpnav {
    width: 100%;
    height: 60px;
    background: #ffffff;
    border: 1px solid #dddddd;
    // 缩宽
    .warpnavs {
      width: 65%;
      height: 100%;
      margin: auto;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      // 知识套餐概述
      span {
        width: auto;
        height: auto;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
      }
      // 竖线
      .line {
        width: 1px;
        height: 14px;
        background: #cccccc;
        margin: 0px 40px;
        margin-top: 5px;
      }
      // 转换内容 选中
      .warpnavsCon {
        width: 70px;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        cursor: pointer;
        span {
          width: auto;
          height: auto;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ff4027;
          margin-top: 20px;
        }
        div {
          width: 30px;
          height: 3px;
          margin-top: 14px;
          background: linear-gradient(90deg, #ff4027 0%, #ff5400 100%);
        }
      }
      // 转换内容 未选中
      .warpnavsCons {
        width: 70px;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        cursor: pointer;
        span {
          width: auto;
          height: auto;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666666;
          margin-top: 20px;
        }
      }
    }
  }
  // 直播课内容
  .warpCon {
    width: 100%;
    // height: 920px;
    height: auto;
    margin-bottom: 30px;
    background: #f5f6fa;
    overflow: hidden;
    // 缩宽
    .warpCons {
      width: 65%;
      height: 100%;
      margin: auto;
      overflow: hidden;
      margin-bottom: 50px;
      .warpConss {
        width: 100%;
        height: auto;
        // 单个内容
        .warpConske {
          width: 100%;
          height: 76px;
          margin-top: 30px;
          background: #ffffff;
          border: 1px solid #ffc3bb;
          border-radius: 2px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          // 左侧标题
          .warpConskeL {
            width: auto;
            height: 19px;
            margin-left: 30px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            img {
              width: 21px;
              height: 19px;
              margin-right: 16px;
              cursor: pointer;
            }
            p {
              width: auto;
              height: auto;
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #27323f;
              cursor: pointer;
              span {
                width: auto;
                height: auto;
                margin-left: 20px;
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #7b8196;
              }
            }
          }
          // 右侧展开/收起
          .warpConskeR {
            width: auto;
            height: 15px;
            margin-right: 30px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            cursor: pointer;
            img {
              width: 9px;
              height: 7px;
              margin-left: 7px;
            }
            span {
              width: auto;
              height: auto;
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #7b8196;
            }
          }
        }
        // 单个内容展开项盒子
        .warpConskexq {
          width: 100%;
          height: auto;
          background: #ffffff;
          padding: 20px;
          .warpConskexqs {
            width: 1175px;
            height: 50px;
            background: #f7f7f7;
            display: flex;
            justify-content: space-between;
            align-items: center;
            // 左侧
            .warpConskexqsL {
              width: auto;
              height: 100%;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              div {
                width: 4px;
                height: 50px;
                background: #dddddd;
                margin-right: 27px;
              }
              span {
                width: auto;
                height: auto;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #333333;
                cursor: pointer;
              }
            }
            // 右侧
            .warpConskexqsR {
              width: 320px;
              height: 32px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              div {
                width: 90px;
                height: 32px;
                margin-right: 10px;
                border-radius: 16px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #ffffff;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
    .apple {
      width: 65%;
      height: 100%;
      margin: 30px auto;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      .appleLeft {
        width: 885px;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        background: #ffffff;
        .appleLefts {
          width: 750px;
          height: auto;
          margin: 60px auto;
          /deep/ img {
            width: 100% !important;
          }
        }
      }
    }
  }
}
</style>
